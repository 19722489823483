import { Component, Input, OnInit } from '@angular/core';
import { PreguntaInterface } from 'src/app/models/interfaces/pregunta.interface';

@Component({
  selector: 'app-pregunta-frecuente',
  templateUrl: './pregunta-frecuente.component.html',
  styleUrls: ['./pregunta-frecuente.component.scss'],
})
export class PreguntaFrecuenteComponent implements OnInit {

  @Input() pregunta: PreguntaInterface;
  expanded: boolean = false;
  
  constructor() { }

  ngOnInit() {}

}
