import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TechComponent } from 'src/app/components/tech/tech.component';
import { AnimatedbckComponent } from 'src/app/components/animatedbck/animatedbck.component';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { I18NextModule } from 'angular-i18next';
import { ServicioBonoComponent } from 'src/app/components/servicio-bono/servicio-bono.component';
import { ContactFormComponent } from 'src/app/components/contact-form/contact-form.component';
import { PreguntaFrecuenteComponent } from 'src/app/components/pregunta-frecuente/pregunta-frecuente.component';
import { ContactFromComponent } from 'src/app/components/contact-from/contact-from.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    TechComponent,
    AnimatedbckComponent,
    HeaderComponent,
    ServicioBonoComponent,
    ContactFormComponent,
    PreguntaFrecuenteComponent,
    ContactFromComponent
  ],
  imports: [
    CommonModule,
    I18NextModule,
    FormsModule
  ],
  exports: [
    TechComponent,
    AnimatedbckComponent,
    HeaderComponent,
    ServicioBonoComponent,
    ContactFormComponent,
    PreguntaFrecuenteComponent,
    ContactFromComponent
  ]
})
export class ComponentesModule { }
