import { Component, Input, OnInit } from '@angular/core';
import * as THREE from 'three';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { Tween, Easing } from 'tween';

@Component({
  selector: 'app-animatedbck',
  templateUrl: './animatedbck.component.html',
  styleUrls: ['./animatedbck.component.scss'],
})
export class AnimatedbckComponent implements OnInit {


  @Input() isDetailView: boolean = false;

  autorotateTimeout;
  
  constructor() { }

  ngOnInit() {
    this.init()
  }

  init(){
    const canvas = document.getElementById('canvas');
    const scene = new THREE.Scene();
    scene.background = new THREE.Color( 0xfffcff );
    const loader = new GLTFLoader();
    loader.load('assets/3d/baywi.glb', (glb)=>{
      console.log("gltf", glb)
      const root = glb.scene;
      if ( !this.isDetailView ){
        root.position.setX(1.9)
        root.position.setY(-0.3)
      }else{
        root.position.setX(-0.4)
        root.position.setY(-0.7)

      }

      root.scale.set(2.5,2.5,2.5)
      scene.add(root);
      
    }, (xhr)=>{
      console.log("xhr", (xhr.loaded/xhr.total*100) + "% loaded");
    }, (err)=>{
      console.error(err)
    })
    
    const light = new THREE.DirectionalLight(0xffffff, 1);
    light.position.set(2,2,5);
    scene.add(light)

    const light2 = new THREE.DirectionalLight(0xffffff, 1);
    light2.position.set(-2,-2,-5);
    scene.add(light2)

    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight
    }

    const camera = new THREE.PerspectiveCamera(75, sizes.width/sizes.height, 0.1, 100);
    camera.position.set(0,0,2.5);
    scene.add(camera);

    const renderer = new THREE.WebGL1Renderer({
      canvas: canvas
    })
    renderer.setClearColor( 0xffffff, 0 ); // second param is opacity, 0 => transparent

    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio,2));
    renderer.shadowMap.enabled = true;
    

    let controls;
    controls = new OrbitControls( camera, renderer.domElement );
    controls.enabled = true;
    // if ( !this.isDetailView ){
    //   controls.autoRotate = true;
    //   controls.autoRotateSpeed *= -4
    //   controls.maxDistance= 2
    // }
     

    const render = () => {
      if ( !this.isDetailView ) controls.update();
      camera.lookAt( scene.position );
      renderer.render( scene, camera );
    }
    function animate(){
      requestAnimationFrame( animate );
      render();
    }
    animate()
    

    


    
    

  }
}
