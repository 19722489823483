import { Component, OnInit } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';
import { ContactFormPage } from 'src/app/modals/contact-form/contact-form.page';
import { ElementsService } from 'src/app/services/elements/elements.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  expanded:boolean = false;
  language:string;
  menu: boolean = false;
  sectionVisible:number=0;
  settingVariable: boolean = false;
  
  languages:{key:string, complete:string}[] = [
    {
      key: 'es', complete:this.i18nextS.transform('logo.es')
    },
    {
      key: 'en', complete:this.i18nextS.transform('logo.en')
    },
    {
      key: 'de', complete:this.i18nextS.transform('logo.de')
    },{
      key: 'fr', complete:this.i18nextS.transform('logo.fr')
    },
    {
      key: 'nr', complete:this.i18nextS.transform('logo.nr')
    },
  ];
  constructor(
    private elementService: ElementsService,
    public i18nextS: I18NextPipe,
  ) { 
    this.language = window.localStorage.getItem('language');

  }

  ngOnInit() {}

  goToContact(){
    this.elementService.presentModal(ContactFormPage,'',{})
  }

  changeLanguage(lang){
    window.localStorage.setItem('language', lang);
    window.location.href="./"
  }
}
