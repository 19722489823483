import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentesModule } from './modules/componentes-module/componentes-module.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { defaultInterpolationFormat, I18NextModule, I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import Backend from 'i18next-http-backend';

export function appInit(i18next: ITranslationService) {
  return () => i18next
  .use(Backend)
  .init({
      fallbackLng: window.localStorage.getItem('language') || 'es',
      debug: true,
      returnEmptyString: false,
      ns: [
        'translation',
        'validation',
        'error'          
      ],
      interpolation: {
        format: I18NextModule.interpolationFormat(defaultInterpolationFormat)
      },
      backend: {
        loadPath: 'assets/locales/{{lng}}.translations.json',
        allowMultiLoading: true
      }
    });
}

export function localeIdFactory(i18next: ITranslationService)  {
  return i18next.language;
}

export const I18N_PROVIDERS = [
{
  provide: APP_INITIALIZER,
  useFactory: appInit,
  deps: [I18NEXT_SERVICE],
  multi: true
},
{
  provide: LOCALE_ID,
  deps: [I18NEXT_SERVICE],
  useFactory: localeIdFactory
}];

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule, 
    I18NextModule.forRoot(),
    ComponentesModule, 
    BrowserAnimationsModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, I18N_PROVIDERS],
  bootstrap: [AppComponent],
})
export class AppModule {}
