import { Component, OnInit } from '@angular/core';
import { ElementsService } from 'src/app/services/elements/elements.service';

@Component({
  selector: 'app-contact-from',
  templateUrl: './contact-from.component.html',
  styleUrls: ['./contact-from.component.scss'],
})
export class ContactFromComponent implements OnInit {

  name: string;
  phone: string;
  email: string;
  companyName: string;
  message: string;

  enviado:boolean = false;
  constructor(
    private elementService: ElementsService
  ) { }

  ngOnInit() {}

  enviarConsulta(){
    if ( this.completeForm() ){
      this.enviado = true;
      let timeout = setTimeout(() => {
        clearTimeout(timeout);
      }, 1000);
    }else{
      this.elementService.presentToast('Contacto', 'Por favor, rellena los datos', 'Vale', '', 'warning', 'bottom')
    }
  }

  completeForm(){
    if (!this.name){
      return false;
    }
    if (!this.email){
      return false;
    }
    if (!this.companyName){
      return false;
    }
    if (!this.phone){
      return false;
    }
    if (!this.message){
      return false;
    }
    return true;
  }

  deleteForm(){
    this.name = null;
    this.phone = null;
    this.email = null;
    this.companyName = null;
    this.message = null;
  }
}
