import { Injectable } from '@angular/core';
import { ToastController, LoadingController, ModalController, AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ElementsService {


  loading;
  modal;
  constructor(
    public toastController: ToastController,
    public loadingController: LoadingController,
    public modalController: ModalController,
    public alertController: AlertController
  ) { }

  async presentToast(title: string, message: string, button: string, icono?: string, color?: string, position?: 'bottom' | 'top' | 'middle') {
    const toast = await this.toastController.create({
      header: title,
      duration: 3000,
      message,
      color,
      position: position ? position : 'bottom',
      cssClass: 'opacity: 1',
      buttons: [
        {
          side: 'end',
          icon: icono? icono: 'warning',
          text: button,
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    });
    await toast.present();
  }

  async presentLoading(text: string) {
    this.loading = await this.loadingController.create({
      message: text,
      duration: 5000,
    });
    await this.loading.present();

  }
  async presentLoadingLimiteless(text: string) {
    this.loading = await this.loadingController.create({
      message: text
    });
    await this.loading.present();
  }

  closeLoading() {
    setTimeout(() => {
      this.loading = this.loadingController.dismiss();
    }, 1000);
  }

  async presentModal(page, clase, props) {
    return new Promise(async (resolve, reject) => {
      this.modal = await this.modalController.create({
        component: page,
        cssClass: clase,
        componentProps: props
      });
      await this.modal.present();
      const { data } = await this.modal.onWillDismiss();
      if (data) {
        resolve(data);
      } else {
        reject();
      }

    });
  }

  closeModal(){
    this.modalController.dismiss();
    this.modal = null;
  }
  isModalOpen(){
    if ( this.modal ) {return true;}
    return false;
  }
  presentAlertConfirm(titulo, mensaje, textoCancelar, textoOk) {
    return new Promise( async (resolve, reject) => {

      const alert = await this.alertController.create({
        cssClass: 'myConfirm',
        header: titulo,
        message: mensaje,
        backdropDismiss: false,
        buttons: [
          {
            text: textoOk,
            handler: (ok) => {
              resolve(ok);
            }
          }, {
            text: textoCancelar,
            role: 'cancel',
            cssClass: 'secondary',
            handler: (cancel) => {
              reject(cancel);
            }
          }
        ]
      });

      await alert.present();
    });
  }

  presentAlertRadio(titulo, opciones, clase, botonCancelar, botonOk) {
    return new Promise(async (resolve, reject) => {

      const alert = await this.alertController.create({
        cssClass: clase,
        header: titulo,
        inputs: opciones,
        buttons: [
          {
            text: botonCancelar,
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              reject();
            }
          }, {
            text: botonOk,
            handler: (data) => {
              resolve(data);
            }
          }
        ]
      });

      await alert.present();
    });
  }


  modalBackdrop;
  async presentModalBackdrop(page, clase, props) {
    return new Promise(async (resolve, reject) => {
      this.modalBackdrop = await this.modalController.create({
        component: page,
        cssClass: clase || 'modalProduct',
        componentProps: props,
        backdropDismiss: false
      });
      await this.modalBackdrop.present();
      const { data } = await this.modalBackdrop.onWillDismiss();
      if (data) {
        resolve(data);
      } else {
        reject('malll');
      }

    });
  }
  closeModalBackdrop(){
      this.modalBackdrop.dismiss();
      this.modalBackdrop = null;
  }


  orderArrays(arr1, arr2?) {
    arr1.sort(function(a, b) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    if(arr2) {
      arr2.sort(function(a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    }
  }
}


