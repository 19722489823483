import { Component, Input, OnInit } from '@angular/core';
import { TechInterface } from 'src/app/models/interfaces/tech.interface';

@Component({
  selector: 'app-tech',
  templateUrl: './tech.component.html',
  styleUrls: ['./tech.component.scss'],
})
export class TechComponent implements OnInit {

  @Input() technologie: TechInterface;
  @Input() white: boolean = false;
  mouseInt: boolean = false;
  
  constructor() { }

  ngOnInit() {}

}
