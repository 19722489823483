import { Component } from '@angular/core';
import { trigger, transition, style, animate, query } from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  animations: [
    trigger(
      'inOutAnimationPage', 
      [
        transition(
          ':enter', 
          [
            style({ width: 0, height:0}),
            animate('1s ease-out', 
                    style({ width: '100vw', 'height': '100vh' }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ width: '100vw', 'height': '100vh' }),
            animate('1s ease-in', 
                    style({ width: 0, 'height': '0' }))
          ]
        )
      ]
    )
  ]
})
export class AppComponent {
  constructor() {}
}
