import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ServiceInterface } from 'src/app/models/interfaces/service.interface';

@Component({
  selector: 'app-servicio-bono',
  templateUrl: './servicio-bono.component.html',
  styleUrls: ['./servicio-bono.component.scss'],
})
export class ServicioBonoComponent implements OnInit {

  @Input() servicio: ServiceInterface;
  @Input() index:number;
  @Output() selected = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  selService(){
    this.selected.emit(this.index)
  }
}
